//================= EMAIL INSURED
//================= EMAIL INSURED
//================= EMAIL INSURED

export const emailTemplateSubject = (variables) => `Action Required - ${variables.carrierCompany} premium audit due - # ${variables.policyNumber}`;
export const emailTemplate = (variables) => `
<div style="text-align: center; font-size: 12px; margin:auto; width: 500px">
	<div style="padding: 10px;">
	<h1>Insurance Premium Audit Notification</h1>
	<div style="margin-top: 20px; text-align: left;">
		<span>Hi ${variables.insuredFirstName},</span>
	</div>
	<div style="margin-top: 20px;text-align: left;">
     <span>Your insurance policy requires a routine audit.  Please complete this audit in the next two weeks.</span>
  </div>
	<div style="margin-top: 20px;text-align: left;">
		<span>Failure to comply with this request for an Audit will result in an estimated audit being processed and may jeopardize your insurance coverage with Mount Vernon Fire Insurance Company, United States Liability Insurance Company, and U.S. Underwriters Insurance Company.</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<div><b>Insured Name:</b> ${variables.insuredCompany}</div>
		${(variables.insuredDbaName) && ('<div><b>Insured DBA Name:</b> '+variables.insuredDbaName + '</div>')}
		<div><b>Policy Number</b>: ${variables.policyNumber}</div>
		<div><b>Business Description</b>: ${variables.insuredDescriptionOfOps}</div>
		<div><b>Policy Period</b>: ${variables.policyStart} - ${variables.policyEnd}</div>

	</div>

	<div style="margin-top: 20px;text-align: left;">
    <span>Information needed to complete the audit for the policy period listed above:</span>
    <div>* Monthly Sales Reports Broken out for Each Location (if applicable)</div>
    <div>* State Sales Tax Returns</div>
  </div>

	<div style="margin: 60px">
		<div style="background-color: #0054B8;
    		border-radius: 5px;
    		padding: 15px 20px;
    		color: white;
    		text-decoration: none;
    		font-size: 20px;
    		margin-top: 30px;"
    	>
			<a href='http://auditcake.com/sml/audit/${variables.id}/start' 
				style="text-decoration: none;color: white;"
    	>
    		Start Audit
    	</a>
    </div>
	</div>

	<div>
		<span style='margin-top: 5px; font-size: 10px;'>SML Capital Advisors has been contracted by ${variables.carrierCompany} to perform this premium audit.</span>
		<p><span style='margin-top: 5px; font-size: 10px;'>If you have any questions, please contact us at sml@auditcake.com.</span></p>
	</div>

	<div style="margin-top: 30px; border-top: 1px solid #ccc;"/>
		<div style="padding: 20px;">
		  <img width="200px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/SMLLogo.png?alt=media&token=f4dd7f1d-388f-40cc-bcff-57904c1091f1" alt="SML" />
		</div>
	</div>
</div>
`;

//================= INSURED_EMAIL_NEW_CONTACT
//================= INSURED_EMAIL_NEW_CONTACT
//================= INSURED_EMAIL_NEW_CONTACT

export const emailInsuredNewContactSubject = (variables) => `Action Required - ${variables.carrierCompany} premium audit due - # ${variables.policyNumber}`;
export const emailInsuredNewContactBody = (variables) => `
<div style="text-align: center; font-size: 12px; margin:auto; width: 500px">
	<div style="padding: 10px;">
	<h1>Insurance Premium Audit Notification</h1>
	<div style="margin-top: 20px; text-align: left;">
		<span>Hi ${variables.agentInsuredFirstName},</span>
	</div>
	<div style="margin-top: 20px;text-align: left;">
		<span>Your insurance policy has expired and requires a routine audit.</span>
	</div>
	<div style="margin-top: 20px;text-align: left;">
		<span>Failure to comply with this request for an Audit will result in an estimated audit being processed and may jeopardize your insurance coverage with Mount Vernon Fire Insurance Company, United States Liability Insurance Company, and U.S. Underwriters Insurance Company.</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<div><b>Insured Name:</b> ${variables.insuredCompany}</div>
		${(variables.insuredDbaName) && ('<div><b>Insured DBA Name:</b> '+variables.insuredDbaName + '</div>')}
		<div><b>Policy Number</b>: ${variables.policyNumber}</div>
		<div><b>Business Description</b>: ${variables.insuredDescriptionOfOps}</div>
		<div><b>Policy Expiration</b>: ${variables.policyEnd}</div>

	</div>

	<div style="margin: 60px">
		<a href='http://auditcake.com/sml/audit/${variables.id}/start' 
			style="background-color: #0054B8;
    		border-radius: 5px;
    		padding: 15px 50px;
    		color: white;
    		text-decoration: none;
    		font-size: 20px;
    		margin-top: 30px;"
    	>
    		Start Audit
    	</a>
	</div>

	<div>
		<span style='margin-top: 5px; font-size: 10px;'>SML Capital Advisors has been contracted by ${variables.carrierCompany} to perform this premium audit.</span>
		<p><span style='margin-top: 5px; font-size: 10px;'>If you have any questions, please contact us at sml@auditcake.com.</span></p>
	</div>

	<div style="margin-top: 30px; border-top: 1px solid #ccc;"/>
		<div style="padding: 20px;">
		  	<img width="200px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/SMLLogo.png?alt=media&token=f4dd7f1d-388f-40cc-bcff-57904c1091f1" alt="SML" />
		</div>
	</div>
</div>
`;

//================= EMAIL INSURED 2X
//================= EMAIL INSURED 2X
//================= EMAIL INSURED 2X

export const emailTemplateSubject2X = (variables) => `Second Reminder: Action Required - ${variables.carrierCompany} premium audit due - # ${variables.policyNumber}`;
export const emailTemplate2X = (variables) => `
<div style="text-align: center; font-size: 12px; margin:auto; width: 500px">
	<div style="padding: 10px;">
	<h1>Insurance Premium Audit Notification</h1>
	<div style="margin-top: 20px; text-align: left;">
		<span>Hi ${variables.insuredFirstName},</span>
	</div>
	
	<div style="margin-top: 20px;text-align: left;">
		<span>Your insurance policy has expired and requires a routine audit.</span>
	</div>
	<div style="margin-top: 20px;text-align: left;">
		<span>Failure to comply with this request for an Audit will result in an estimated audit being processed and may jeopardize your insurance coverage with Mount Vernon Fire Insurance Company, United States Liability Insurance Company, and U.S. Underwriters Insurance Company.</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<div><b>Insured Name:</b> ${variables.insuredCompany}</div>
		${(variables.insuredDbaName) && ('<div><b>Insured DBA Name:</b> '+variables.insuredDbaName + '</div>')}
		<div><b>Policy Number</b>: ${variables.policyNumber}</div>
		<div><b>Business Description</b>: ${variables.insuredDescriptionOfOps}</div>
		<div><b>Policy Expiration</b>: ${variables.policyEnd}</div>

	</div>

	<div style="margin: 60px">
		<a href='http://auditcake.com/sml/audit/${variables.id}/start' 
			style="background-color: #0054B8;
    		border-radius: 5px;
    		padding: 15px 50px;
    		color: white;
    		text-decoration: none;
    		font-size: 20px;
    		margin-top: 30px;"
    	>
    		Start Audit
    	</a>
	</div>

	<div>
		<span style='margin-top: 5px; font-size: 10px;'>SML Capital Advisors has been contracted by ${variables.carrierCompany} to perform this premium audit.</span>
		<p><span style='margin-top: 5px; font-size: 10px;'>If you have any questions, please contact us at sml@auditcake.com.</span></p>
	</div>

	<div style="margin-top: 30px; border-top: 1px solid #ccc;"/>
		<div style="padding: 20px;">
	  	<img width="200px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/SMLLogo.png?alt=media&token=f4dd7f1d-388f-40cc-bcff-57904c1091f1" alt="SML" />
		</div>
	</div>
</div>
`;

//================= EMAIL INSURED 3X
//================= EMAIL INSURED 3X
//================= EMAIL INSURED 3X

export const emailTemplateSubject3X = (variables) => `Final Reminder: Action Required - ${variables.carrierCompany} premium audit due - # ${variables.policyNumber}`;
export const emailTemplate3X = (variables) => `
<div style="text-align: center; font-size: 12px; margin:auto; width: 500px">
	<div style="padding: 10px;">
	<h1>Insurance Premium Audit Notification</h1>
	<div style="margin-top: 20px; text-align: left;">
		<span>Hi ${variables.insuredFirstName},</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<span>Your insurance policy has expired and requires a routine audit.</span>
	</div>
	<div style="margin-top: 20px;text-align: left;">
		<span>Failure to comply with this request for an Audit will result in an estimated audit being processed and may jeopardize your insurance coverage with Mount Vernon Fire Insurance Company, United States Liability Insurance Company, and U.S. Underwriters Insurance Company.</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<div><b>Insured Name:</b> ${variables.insuredCompany}</div>
		${(variables.insuredDbaName) && ('<div><b>Insured DBA Name:</b> '+variables.insuredDbaName + '</div>')}
		<div><b>Policy Number</b>: ${variables.policyNumber}</div>
		<div><b>Business Description</b>: ${variables.insuredDescriptionOfOps}</div>
		<div><b>Policy Expiration</b>: ${variables.policyEnd}</div>

	</div>

	<div style="margin: 60px">
		<a href='http://auditcake.com/sml/audit/${variables.id}/start' 
			style="background-color: #0054B8;
    		border-radius: 5px;
    		padding: 15px 50px;
    		color: white;
    		text-decoration: none;
    		font-size: 20px;
    		margin-top: 30px;"
    	>
    		Start Audit
    	</a>
	</div>

	<div>
		<span style='margin-top: 5px; font-size: 10px;'>SML Capital Advisors has been contracted by ${variables.carrierCompany} to perform this premium audit.</span>
		<p><span style='margin-top: 5px; font-size: 10px;'>If you have any questions, please contact us at sml@auditcake.com</span></p>
	</div>

	<div style="margin-top: 30px; border-top: 1px solid #ccc;"/>
		<div style="padding: 20px;">
		  <img width="200px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/SMLLogo.png?alt=media&token=f4dd7f1d-388f-40cc-bcff-57904c1091f1" alt="SML" />
		</div>
	</div>
</div>
`;

//================= EMAIL INSURED SUBMIT DOCS
//================= EMAIL INSURED SUBMIT DOCS
//================= EMAIL INSURED SUBMIT DOCS
const insuredTable = (cats, totals) => {
  let t = '';
  const th = "background-color: #f2f2f2;border: 1px solid #ddd;padding: 8px;text-align: left;"
  const td = "border: 1px solid #ddd;padding: 8px;text-align: left;"

  // Build the table header conditionally
  if (cats.length > 0) {
    t += `<table style="border-collapse: collapse;width: 100%;margin: 5px auto;">
      <thead>
        <tr>
          <th style="${th}">Category</th>
          <th style="${th}">Gross Sales (incl taxes)</th>
          <th style="${th}">Taxes</th>
        </tr>
      </thead>
      <tbody>`;
  }

  // Loop through cats and build table rows
  for (const c of cats) {
  	if (c.selected) {
	    t += `<tr>
	      <td style="${td}">${c.label}</td>
	      <td style="${td}">${c.sales}</td>
	      <td style="${td}">${c.salesTax}</td>
	    </tr>`;
	}
  }

  // Build the totals row conditionally
  if (totals) {
    t += `<tr>
      <td style="${td}"><b>Total</b></td>
      <td style="${td}"><b>${totals.salesTotal}</b></td>
      <td style="${td}"><b>${totals.salesTaxTotal}</b></td>
    </tr>`;
  }

  // Close the table body and table tags
  t += `</tbody></table>`;

  return t;
}

export const emailInsuredSubmitDocsSubject = (variables) => `Action Required - Provide backup documents for your ${variables.carrierCompany} policy # ${variables.policyNumber}`;
export const emailInsuredSubmitDocsBody = (variables) => `
<div style="text-align: center; font-size: 12px; margin:auto; width: 500px">
	<div style="padding: 10px;">
	<h1>Insurance Premium Audit Notification</h1>
	<div style="margin-top: 20px; text-align: left;">
		<span>Hi ${variables.insuredFirstName},</span>
	</div>
	<div style="margin-top: 20px;text-align: left;">
		<span>Thank you for submitting your USLI audit.  You are almost done!</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<span>We are required to collect backup documents to validate the numbers you provided below.</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<span>Please reply to this email with <b>sales tax records</b> and <b>financial statements</b> for your policy period: <b>${variables.policyStart}</b> to <b>${variables.policyEnd}</b>.</span>
	</div>

	<div style="margin: 60px">
		${insuredTable(variables.insuredCategories, variables.insuredCategoryTotals)}
	</div>


	<div>
		<span style='margin-top: 5px; font-size: 10px;'>SML Capital Advisors has been contracted by ${variables.carrierCompany} to perform this premium audit.</span>
		<p><span style='margin-top: 5px; font-size: 10px;'>If you have any questions, please contact us at sml@auditcake.com.</span></p>
	</div>

	<div style="margin-top: 30px; border-top: 1px solid #ccc;"/>
		<div style="padding: 20px;">
		  <img width="200px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/SMLLogo.png?alt=media&token=f4dd7f1d-388f-40cc-bcff-57904c1091f1" alt="SML" />
		</div>
	</div>
</div>
`;

//================= EMAIL INSURED WRONG DOC
//================= EMAIL INSURED WRONG DOC
//================= EMAIL INSURED WRONG DOC
const insuredWrongDocTable = (atts) => {
  const sadDocs = atts.filter(a => a.sad);
  let t = '';
  const th = "background-color: #f2f2f2;border: 1px solid #ddd;padding: 8px;text-align: left;"
  const td = "border: 1px solid #ddd;padding: 8px;text-align: left;"

  // Build the table header conditionally
  if (sadDocs.length > 0) {
    t += `<table style="border-collapse: collapse;width: 100%;margin: 5px auto;">
      <thead>
        <tr>
          <th style="${th}">Document</th>
          <th style="${th}">Audit Note</th>
        </tr>
      </thead>
      <tbody>`;
  }

  // Loop through cats and build table rows
  for (const a of sadDocs) {
    t += `<tr>
      <td style="${td}">${a.fileName}</td>
      <td style="${td}">${a.attSadNote}</td>
    </tr>`;
	
  }

  // Close the table body and table tags
  t += `</tbody></table>`;

  return t;
}

export const emailInsuredWrongDocSubject = (variables) => `Action Required - Update backup documents for your ${variables.carrierCompany} policy # ${variables.policyNumber}`;
export const emailInsuredWrongDocBody = (variables) => `
<div style="text-align: center; font-size: 12px; margin:auto; width: 500px">
	<div style="padding: 10px;">
	<h1>Insurance Premium Audit Notification</h1>
	<div style="margin-top: 20px; text-align: left;">
		<span>Hi ${variables.insuredFirstName},</span>
	</div>
	<div style="margin-top: 20px;text-align: left;">
		<span>Thank you for submitting your USLI audit.  You are almost done!</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<span>We reviewed the documents you provided and identified ones that require follow up.  Please see below, and reply to this email with your corrections.</span>
	</div>

	<div style="margin: 60px">
		${insuredWrongDocTable(variables.atts)}
	</div>


	<div>
		<span style='margin-top: 5px; font-size: 10px;'>SML Capital Advisors has been contracted by ${variables.carrierCompany} to perform this premium audit.</span>
		<p><span style='margin-top: 5px; font-size: 10px;'>If you have any questions, please contact us at sml@auditcake.com.</span></p>
	</div>

	<div style="margin-top: 30px; border-top: 1px solid #ccc;"/>
		<div style="padding: 20px;">
		  <img width="200px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/SMLLogo.png?alt=media&token=f4dd7f1d-388f-40cc-bcff-57904c1091f1" alt="SML" />
		</div>
	</div>
</div>
`;


//================= EMAIL AGENT
//================= EMAIL AGENT
//================= EMAIL AGENT
export const emailAgentForContactSubject = (variables) => `Action Required - ${variables.carrierCompany} premium audit due - # ${variables.policyNumber}`;
export const emailAgentForContact = (variables) => `
<div style="text-align: center; font-size: 12px; margin:auto; width: 500px">
	<div style="padding: 10px;">
		<div style="margin-top: 20px; text-align: left;">
			<span>Hi ${variables.agentFirstName},</span>
		</div>
		<div style="margin-top: 20px;text-align: left;">
			<span>I'm the premium auditor hired by USLI to conduct an audit for your customer.</span>
		</div>

		<div style="margin-top: 20px;text-align: left;">
			<div><b>Customer Name:</b> ${variables.insuredCompany}</div>
			${(variables.insuredDbaName) && ('<div><b>Customer DBA Name:</b> '+variables.insuredDbaName + '</div>')}
			<div><b>Policy Number</b>: ${variables.policyNumber}</div>
			<div><b>Policy Expiration</b>: ${variables.policyEnd}</div>
		</div>

		<div style="margin-top: 20px;text-align: left;">
			<span>We need to compile the actual exposures for the above policy, which should only take a few minutes.  I am missing the insured's contact information - there was no email address provided and the phone number (${variables.insuredPhone}) was unproductive as well.</span>
		</div>

		<div style="margin-top: 20px;text-align: left;">
			<span>Can you please help? Thanks in advance.</span>
		</div>
		<div style="margin-top: 30px; border-top: 1px solid #ccc;"/>
			<div style="padding: 20px;">
			  <img width="200px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/SMLLogo.png?alt=media&token=f4dd7f1d-388f-40cc-bcff-57904c1091f1" alt="SML" />
			</div>
		</div>



	</div>
</div>
`;

//================= EMAIL AGENT KICKOFF
//================= EMAIL AGENT KICKOFF
//================= EMAIL AGENT KICKOFF
export const emailAgentKickOffSubject = (variables) => `Agent Action Required - ${variables.carrierCompany} premium audit for your customer - # ${variables.policyNumber}`;
export const emailAgentKickOffBody = (variables) => `
<div style="text-align: center; font-size: 12px; margin:auto; width: 500px">
	<div style="padding: 10px;">
	<div style="margin-top: 20px; text-align: left;">
		<span>Hi ${variables.agentFirstName},</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<span>Your customer's insurance policy has expired and requires a routine audit, to ensure the policy is priced accurately.</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<div><b>Customer Name:</b> ${variables.insuredCompany}</div>
		${(variables.insuredDbaName) && ('<div><b>Customer DBA Name:</b> '+variables.insuredDbaName + '</div>')}
		<div><b>Policy Number</b>: ${variables.policyNumber}</div>
		<div><b>Policy Expiration</b>: ${variables.policyEnd}</div>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<span>Please provide contact information for the <b>insured</b> or the <b>retail agent</b>, so that we can complete this audit in a timely manner. This will only take a few minutes of your time.</span>
	</div>

	<div style="margin: 60px">
		<a href='http://auditcake.com/sml/r/r${variables.id}/start' 
			style="background-color: #0054B8;
    		border-radius: 5px;
    		padding: 15px 50px;
    		color: white;
    		text-decoration: none;
    		font-size: 20px;
    		margin-top: 30px;"
    	>
    		Provide Information
    	</a>
	</div>

	<div>
		<span style='margin-top: 5px; font-size: 10px;'>SML Capital Advisors has been contracted by ${variables.carrierCompany} to perform this premium audit.</span>
		<p><span style='margin-top: 5px; font-size: 10px;'>If you have any questions, please contact us at sml@auditcake.com.</span></p>
	</div>

	<div style="margin-top: 30px; border-top: 1px solid #ccc;"/>
		<div style="padding: 20px;">
			<img width="200px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/SMLLogo.png?alt=media&token=f4dd7f1d-388f-40cc-bcff-57904c1091f1" alt="SML" />
		</div>
	</div>
</div>
`;

//================= EMAIL PROVIDED AGENT 
//================= EMAIL PROVIDED AGENT 
//================= EMAIL PROVIDED AGENT 
export const emailAgentProvidedSubject = (variables) => `Agent Action Required - ${variables.carrierCompany} premium audit for your customer - # ${variables.policyNumber}`;
export const emailAgentProvidedBody = (variables) => `
<div style="text-align: center; font-size: 12px; margin:auto; width: 500px">
	<div style="padding: 10px;">
	<div style="margin-top: 20px; text-align: left;">
		<span>Hi ${variables.agentProvidedFirstName},</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<span>You've been invited by ${variables.agentEmail} (cc'd) to help out with a routine premium audit for your customer.</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<div><b>Customer Name:</b> ${variables.insuredCompany}</div>
		${(variables.insuredDbaName) && ('<div><b>Customer DBA Name:</b> '+variables.insuredDbaName + '</div>')}
		<div><b>Policy Number</b>: ${variables.policyNumber}</div>
		<div><b>Policy Expiration</b>: ${variables.policyEnd}</div>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<span>Please <b>provide contact information</b> for the insured so that we can complete this audit in a timely manner. This will only take a few minutes of your time.</span>
	</div>

	<div style="margin: 60px">
		<a href='http://auditcake.com/sml/r/x${variables.id}/start' 
			style="background-color: #0054B8;
    		border-radius: 5px;
    		padding: 15px 50px;
    		color: white;
    		text-decoration: none;
    		font-size: 20px;
    		margin-top: 30px;"
    	>
    		Provide Information
    	</a>
	</div>

	<div>
		<span style='margin-top: 5px; font-size: 10px;'>SML Capital Advisors has been contracted by ${variables.carrierCompany} to perform this premium audit.</span>
		<p><span style='margin-top: 5px; font-size: 10px;'>If you have any questions, please contact us at sml@auditcake.com.</span></p>
	</div>

	<div style="margin-top: 30px; border-top: 1px solid #ccc;"/>
		<div style="padding: 20px;">
		  <img width="200px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/SMLLogo.png?alt=media&token=f4dd7f1d-388f-40cc-bcff-57904c1091f1" alt="SML" />
		</div>
	</div>
</div>
`;


//================= EMAIL CONFIRMATION DONE
//================= EMAIL CONFIRMATION DONE
//================= EMAIL CONFIRMATION DONE
export const emailConfDoneSubject = (variables) => `Completed - ${variables.carrierCompany} premium audit - # ${variables.policyNumber}`;
export const emailConfDone = (variables) => `
<div style="text-align: center; font-size: 12px; margin:auto; width: 500px">
	<h1>Insurance Premium Audit Completion</h1>
	<div style="margin-top: 20px; text-align: left;">
		<span>Hi ${variables.insuredFirstName},</span>
	</div>
	<div style="margin-top: 20px;text-align: left;">
		<span>Thank you for completing <b>${variables.carrierCompany}</b> premium audit for policy # <b>${variables.policyNumber}</b>.  A premium audit specialist will be in touch if there are any further questions on your account.</span>
	</div>

	<div style="margin-top: 50px; border-top: 1px solid #ccc;"/>
		
	<div style="padding: 20px;">
		<img width="200px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/SMLLogo.png?alt=media&token=f4dd7f1d-388f-40cc-bcff-57904c1091f1" alt="SML" />
	</div>
	<span style='margin-top: 5px; font-size: 10px;'>SML Capital Advisors has been contracted by ${variables.carrierCompany} to perform this premium audit.</span>

</div>
`;

