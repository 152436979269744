// util helper methods go here... 

import { firestoreMethods } from '../firebase/firestoreMethods';
import { stepValidationMethods } from './stepValidationMethods';
import { stepAutoMethods } from './stepAutoMethods';
import { utilMethods } from './utilMethods';
import { commsMethods } from '../util/commsMethods';



import { functionsMethods } from '../firebase/functionsMethods';

//import stepTypes from './json/stepTypes.json'; // TODO: likely dep
import stepDetails from './json/stepDetails.json';



export const stepMethods = {

  createStep: async (audit, type, nextStepDetails) => {
    console.log('CREATE STEP: ', audit, type, nextStepDetails)
    const newAuditStep = {
      auditFSID: audit.id,
      auditCode: audit.auditCode,
      dateAdded: new Date(),
      type: type,
    }

    if (nextStepDetails) {
      for (const [key, value] of Object.entries(nextStepDetails)) {
        newAuditStep[key] = value;
      }
    }

    const augmentedDetails = stepMethods.getStepDetails(type, audit);
    newAuditStep.historyOp = augmentedDetails.historyOp || '';
    //newAuditStep.displayName = augmentedDetails.name || 'MANUAL';

    // TODO: for now just need historyOp, we'll see later

    //const mergedObject = { ...newAuditStep, ...augmentedDetails };

    console.log('nas: ', newAuditStep, augmentedDetails);

    // do not attempt to add a step if such a step already exists and is not completed
    const stepExists = await stepMethods.checkExists(audit.id, type);
    if (!stepExists) {
      const stepRef = await firestoreMethods.add("asteps", newAuditStep);
      return stepRef.id;
    } 
    return 'STEP_EXISTS';
    /*
    const stepForAudit = [{op: 'union', name: "steps", value: 
      {type: type, typeDetail: typeDetail, stepFSID: stepRef.id },
    }];
           
    await firestoreMethods.update("audits", audit.id, stepForAudit);
    */
    
  }, 

  setComplete: async ({step, nextStepDetails, allSteps, audit, newName, userId}) => {
    // TODO: add some way to also update the step, so that edits are propagated to next step
    console.log('STEP COMPLETE (step, nextStepDetails, allSteps, audit, newName, userId): ', step, nextStepDetails, allSteps, audit, newName, userId);

    const checkComplete = await stepValidationMethods[step.type](step, audit);
    console.log(checkComplete);
    if (checkComplete.status === '200') {
      if (step.type !== 'NEW') {
        // above if checks that for new audits, don't need to complete anything
        const stepUpdates = [{op: '', name: 'dateCompleted', value: new Date()}]
        if (newName) {
          stepUpdates.push({op: '', name: 'historyOp', value: newName});
          stepUpdates.push({op: '', name: 'displayName', value: newName});
        }
        await firestoreMethods.update("asteps", step.id, stepUpdates) 

        //update history TODO: is HISTORY redundant with steps completed?  ITERATING TOWARDS NO, STEPS ARE FUTURE, HISTORY IS PAST + REPORT
        const newHistory = {
            op: newName || step.historyOp,
            text: nextStepDetails?.historyText || '',
            date: new Date(),
            userId: userId || 'System'
        };
        const auditUpdates = [{op: 'union', name: 'history', value: newHistory}]
        await firestoreMethods.update("audits", step.auditFSID, auditUpdates) 
      }
      // get next step
      const nextSteps = await stepAutoMethods[step.type](step, allSteps, audit);
      const nextStepsIds = [];
      for (const nStepType of nextSteps) {
        const nextStepId = await stepMethods.createStep(
          {
            id: step.auditFSID, 
            auditCode: step.auditCode
          }, 
          nStepType, 
          nextStepDetails,
        );
        nextStepsIds.push(nextStepId);
      }

      return {status: "200", nextStepsIds: nextStepsIds};
    
    } else {
      return checkComplete;
    }
  },

  bulkCompleteStep: async (bakeryStep, audits, allSteps) => {
    console.log('bulkCompleteStep (bakeryStep, audits, allSteps)', bakeryStep, audits, allSteps);
    for (const a of audits) {
      const auditSteps = allSteps.filter(s => s.auditFSID === a.id);
      //const s = allSteps.filter(step => step.auditFSID === a.id && step.type === bakeryStep)[0];
      console.log('audit STEPS', auditSteps);
      if (auditSteps.length > 0) {
        await stepMethods.advanceAudit(a, bakeryStep, auditSteps);
      }
    }
  },

  advanceAudit: async (newA, stepKey, auditSteps) => {
  //const advanceAudit = async (newA, stepKey) => {
    console.log("ADVANCE AUDIT (audit, stepKey, auditSteps)", newA, stepKey, auditSteps);
    // TODO: eventually, this needs to happen through server/pub/sub, event driven architecture.
    // get the received info step
    if (!auditSteps) auditSteps = await stepMethods.getSteps(newA.id); 
    console.log("STEPS", auditSteps);
    const advanceStep = auditSteps.find(o => o.type === stepKey)
    console.log("found step: ", advanceStep)
    // execute the step!
    const supportedEmailAutoSteps = [
      'EMAILING_INSURED_DONE',
      'EMAILING_INSURED',
      'INSURED_EMAIL_NEW_CONTACT',
      'AGENT_EMAIL_KICKOFF',
      'AGENT_EMAIL_PROVIDED_AGENT',
    ];

    if (advanceStep && (supportedEmailAutoSteps.includes(stepKey))) {
      await stepMethods.sendEmail(advanceStep, newA.id);
    }

    if (advanceStep && (stepKey === 'MAILING_INSURED')) {
      await stepMethods.sendLetter(advanceStep, newA.id);
    }


    // mark it as complete, and get the next step
    //const nextStepDetails = null;
    if (advanceStep && !advanceStep.dateCompleted) {
      await stepMethods.setComplete({
        step:advanceStep, 
        nextStepDetails: null, 
        allSteps: auditSteps, 
        audit:newA
      }); 
    }
  },

  sendEmail: async (step, auditFSID) => {

    const audit = await firestoreMethods.getD("audits", auditFSID);

    // augment step
    const augmentedDetails = stepMethods.getStepDetails(step.type, audit);
    const augmentedStep = { ...step, ...augmentedDetails };


  //const sendConfEmail = async (step) => {
    console.log('SENDING EMAIL', step, augmentedStep)
    const to = augmentedStep.templateVarsVals[augmentedStep.to];
    const cc = augmentedStep.templateVarsVals[augmentedStep.cc];

    //const to = audit.insuredEmail;
    const subject = commsMethods[augmentedStep.templateSubject](augmentedStep.templateVarsVals)
    const message = commsMethods[augmentedStep.templateName](augmentedStep.templateVarsVals)
    const ac = audit.auditCode;
    const ref = augmentedStep.historyOp;
    const success = await functionsMethods.sendGridEmail(to, cc, subject, message, ac, ref);
    console.log("success: ", success);
  },

  sendLetter: async (step, auditFSID) => {
    // augment step
    const audit = await firestoreMethods.getD("audits", auditFSID);

    const augmentedDetails = stepMethods.getStepDetails(step.type, audit);
    const augmentedStep = { ...step, ...augmentedDetails };

    console.log('SENDING LETTER MAIL', step, augmentedStep);

    const mailContent = commsMethods[augmentedStep.templateName](augmentedStep.templateVarsVals)


    const stepMailProps = (augmentedStep.addressType === 'MAILING') ? {
      name: audit.insuredCompany.substring(0,40), // to pass LOB validation
      address_line1: audit.insuredMailingAddress1,
      address_line2: audit.insuredMailingAddress2,
      address_city: audit.insuredMailingCity,
      address_state: audit.insuredMailingState,
      address_zip: audit.insuredMailingZip
    } 
    :
    {
      name: audit.insuredCompany.substring(0,40), // to pass LOB validation
      address_line1: audit.insuredLocationAddress1,
      address_line2: audit.insuredLocationAddress2,
      address_city: audit.insuredLocationCity,
      address_state: audit.insuredLocationState,
      address_zip: audit.insuredLocationZip
    };



    //const ac = audit.auditCode;
    //const ref = step.historyOp;
    const success = await functionsMethods.sendLOBLetter(mailContent, stepMailProps);
    console.log("success: ", success);
  },

  checkExists: async (auditID, stepKey) => {
  //const advanceAudit = async (newA, stepKey) => {
    console.log("CHECK EXISTS (auditID, stepKey,)", auditID, stepKey);
    // TODO: eventually, this needs to happen through server/pub/sub, event driven architecture.
    // get the received info step
    const auditSteps = await stepMethods.getSteps(auditID); 
    console.log("STEPS", auditSteps);
    const checkStep = auditSteps.find(o => o.type === stepKey)
    console.log("found step: ", checkStep)
    
    // mark it as complete, and get the next step
    //const nextStepDetails = null;
    return (checkStep && !checkStep.dateCompleted) ? true : false;
  },

  getSteps: async (auditFSID) => {
    const filter = {name: 'auditFSID', op: '==', value: auditFSID}
    const newData = await firestoreMethods.get("asteps", filter);
    return newData;
  },

  getStepDetails: (stepRef, audit) => {
    console.log('getStepDetails (stepref, audit): ', stepRef, audit)
    const thisStepDetails = stepDetails[stepRef];
    if (thisStepDetails) {
      thisStepDetails["templateVarsVals"] = {}
      if (thisStepDetails.templateVars) {
        for (const tVar of thisStepDetails.templateVars) {
          thisStepDetails["templateVarsVals"][tVar] = 
            (thisStepDetails?.dateVars?.includes(tVar)) ?
              utilMethods.formatDate(audit[tVar]) :
              audit[tVar];
        }
      } 
    }
    //console.log("thisStepDetails", thisStepDetails);
    return thisStepDetails;
  },

   getStepTypes: () => {
     const steps = Object.keys(stepDetails).sort();
     let retArr = []
     for (const s of steps) {
      retArr.push({
        id: s, 
        name: stepDetails[s].name,
        cat: stepDetails[s].cat,
      })
     }
     return utilMethods.sortBy(retArr, 'name');
  },
/*
  sortSteps: (arr) => {
    const sortedArray = arr.sort(function(a, b) {
        // currently sorting with most recent step first
        var x = a.dateCompleted;
        var y = b.dateCompleted;
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
     });
    console.log('sorting steps', arr, sortedArray);

    return sortedArray;
  },*/

  sortSteps: (data, field1 = 'dateCompleted', field2 = 'dateStarted') => {
    return data.sort((obj1, obj2) => {
      const hasField1_1 = obj1.hasOwnProperty(field1) && obj1[field1] !== null;
      const hasField1_2 = obj2.hasOwnProperty(field1) && obj2[field1] !== null;

      // Sort by presence of field1 (with values) descending
      if (hasField1_1 !== hasField1_2) {
        return hasField1_2 ? -1 : 1;
      }

      // If both have or lack field1, sort by field2 (ascending)
      if (hasField1_1 && hasField1_2) {
        const date1 = obj1[field2];
        const date2 = obj2[field2];
        return date1 === null ? 1 : (date2 === null ? -1 : new Date(date1) - new Date(date2));
      }

      return 0;
    });
  },

    /*

  getStatusLabel: (audit) => {
    const step = stepMethods.getLastAuditStep(audit.steps);
    if (step) {
      return stepTypes[step.type]["status"] || "N/A";
    }
    return "N/A";
  },

  getLastAuditStep: (arr) => {
    return (arr && arr.length) ? arr[arr.length - 1] : null
  }, 


  getStepTypes: () => {
    return stepTypes;
  },
*/
}